<template>
  <div>
    <v-dialog v-model="dialog" max-width="450px">
      <v-card>
        <v-card-title
          style="font-family: 'Montserrat';"
          class="font-weight-bold flex justify-center"
          >Seleccione los reportes <br />
          que desea recibir
        </v-card-title>
        <!-- lista checkboxes -->
        <!-- 1. Cobranzas -->
        <v-card-text>
          <v-checkbox
            v-model="envio.Cobranzas"
            label="Cobranzas"
            color="primary"
            hide-details
          ></v-checkbox>
          <!-- 2. Rendiciones -->
          <v-checkbox
            v-model="envio.Rendiciones"
            label="Rendiciones"
            color="primary"
            hide-details
          ></v-checkbox>
          <!-- 3. Reversiones -->
          <v-checkbox
            v-model="envio.Reversiones"
            label="Reversiones"
            color="primary"
            hide-details
          ></v-checkbox>
          <!-- 4. Batch -->
          <v-checkbox
            v-model="envio.Batch"
            label="Batch"
            color="primary"
            hide-details
          ></v-checkbox>
          <!-- 5. Batch Pagos -->
          <v-checkbox
            v-model="envio.BatchPagos"
            label="Batch Pagos"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            color="primary"
            text
            @click="actualizarEnvios()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Clientes } from "@/services/clientes.js";
export default {
  name: "ModalEnvios",
  data() {
    return {
      dialog: false,
      loading: false,

      clientes_id: "",
      cliente: "",
      envio: "",
    };
  },
  mounted() {
    this.$on("showModalEnvios", (item) => {
      this.dialog = true;
      this.clientes_id = item.id;
      this.cliente = item.cliente;
      this.envio = item.envio;
    });
  },
  methods: {
    async actualizarEnvios() {
      const payload = {
        id: this.envio.ID ? this.envio.ID : this.clientes_id,
        clientes_id: this.clientes_id,
        cobranzas: this.envio.Cobranzas,
        rendiciones: this.envio.Rendiciones,
        reversiones: this.envio.Reversiones,
        batch: this.envio.Batch,
        batch_pagos: this.envio.BatchPagos,
      };
      try {
        this.loading = true;
        const { data } = await Clientes.actualizarEnvios(payload);
        this.loading = false;
        this.dialog = false;
        if (data.status == 200) {
          this.$toastr.s("Envios actualizados correctamente");
        }
      } catch (error) {
        this.loading = false;
        this.$toastr.e("Error al actualizar los envios");
        // console.log(error);
      }
    },
  },
};
</script>

<style scoped></style>
