import axios from 'axios';
import './axiosInterceptor';
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const Impuestos = {

  getImpuestos(params) {
    return axios.get(PASARELA + "/administracion/impuestos", { params })
  },

}
