<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px" @click:outside="closeDialog()">
      <v-app>
        <v-main>
          <v-container fluid>
            <v-card-title
              style="font-family: 'Montserrat' !important; font-weight: bold;"
            >
              Contactos del cliente {{ this.cliente }}
              <v-spacer></v-spacer>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="closeDialog">
                    <v-icon>close</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </v-card-title>

            <!-- FILTRO BUSCAR -->
            <section>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-tooltip left color="grey darken-1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      @click="showDialogForm(null)"
                      fab
                    >
                      <v-icon>mdi-email-plus-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Agregar Contacto</span>
                </v-tooltip>
              </v-card-title>
              <!-- tabla de contactos -->
              <v-data-table
                height="250px"
                :search="search"
                :headers="headers"
                :items="contactos"
                :loading="cargando"
                class="elevation-0 trow"
                disable-pagination
                hide-default-footer
                loading-text="Cargando... Por favor espere"
                no-data-text="No se encontraron registros"
                no-results-text="No se encontraron registros que coincidan"
              >
                <!-- BOTONES DE LA TABLA -->
                <!-- 1. Editar -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top color="grey darken-1">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        fab
                        dark
                        x-small
                        color="orange"
                        v-on="on"
                        @click="showDialogForm(item)"
                      >
                        <v-icon dark> create </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar Contacto</span>
                  </v-tooltip>
                  <!-- 2. Eliminar -->
                  <v-tooltip top color="grey darken-1">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="eliminarContacto(item)"
                        fab
                        dark
                        x-small
                        color="red"
                        v-on="on"
                      >
                        <v-icon dark> delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar Contacto</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </section>
          </v-container>
        </v-main>
      </v-app>
    </v-dialog>
    <!-- MODAL EDITAR/CREAR CONTACTO -->
    <v-dialog v-model="dialogForm" max-width="600px" persistent>
      <v-card>
        <v-card-title
          style="font-family: 'Montserrat';"
          class="font-weight-bold"
        >
          {{ editar ? "Modificar Contacto" : "Agregar Contacto" }}
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <!-- BOTON X -->
              <v-btn @click="closeForm()" icon v-bind="attrs" v-on="on">
                <v-icon>close</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>

        <v-card-text>
          <div>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="email"
                :rules="rules_email"
                label="Email"
                required
              ></v-text-field>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- BOTON Cerrar -->
          <v-btn color="grey darken-1" text @click="closeForm">
            Cerrar
          </v-btn>
          <!-- BOTON Guardar -->
          <v-btn
            :disabled="cargando || !valid"
            color="green"
            class="font-weight-bold"
            style="font-family: 'Montserrat';"
            text
            @click="guardarOptions"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Contactos } from "@/services/contactos";

export default {
  name: "DialogContactos",

  data() {
    return {
      procensando_datos: false,
      dialog: false,
      dialogForm: false,

      search: "",

      editar: false,

      cargando: false,
      valid: false,

      cliente: "",
      cliente_id: "",
      email: "",
      contactos: [],

      //para editar
      email_anterior: "",

      headers: [
        { text: "Email", value: "cliente_email" },
        { text: "Acciones", value: "actions", sortable: false },
      ],

      //rules
      rules_email: [
        (v) => !!v || "El email es requerido",
        (v) => /.+@.+\..+/.test(v) || "El email debe ser válido",
      ],
    };
  },
  mounted() {
    this.$on("showDialogContactos", (item) => {
      this.dialog = true;
      this.cliente_id = item.id;
      this.cliente = item.cliente;
      this.getContactosData();
    });
  },
  methods: {
    //LISTA contactos
    async getContactosData() {
      const data = {
        clienteid: this.cliente_id,
      };
      try {
        this.cargando = true;
        const response = await Contactos.getContactos(data);
        this.cargando = false;

        if (response.data.status === false) {
          this.contactos = [];
          this.$toastr.w(response.data.message);
        } else {
          this.contactos = response.data.data;
        }
      } catch (error) {
        this.cargando = false;
        if (error.response.status === 400) {
          this.$toastr.e(error.response.data.message);
        } else {
          this.$toastr.e("Error al obtener los contactos");
        }
      }
    },
    //EDITAR contacto
    async editarContacto() {
      const payload = {
        cliente_id: this.cliente_id,
        cliente_email: this.email_anterior,
        cliente_email_nuevo: this.email,
      };

      this.validate();

      if (this.res) {
        try {
          this.cargando = true;
          const { data } = await Contactos.editarContacto(payload);
          this.cargando = false;
          if (data.status === false) {
            return this.$toastr.e(data.message);
          }
          this.$toastr.s(data.statusMessage);

          this.getContactosData();
          this.closeForm();
        } catch (error) {
          this.cargando = false;
          if (error.response.status === 400) {
            this.$toastr.e(error.response.data.message);
          } else {
            this.$toastr.e("Error al editar el contacto");
          }
        }
      } else {
        this.$toastr.e("Debe completar los campos requeridos correctamente");
      }
    },
    //ELIMINAR contacto
    async eliminarContacto(item) {
      const payload = {
        cliente_email: item.cliente_email,
        cliente_id: item.cliente_id,
      };

      const { isConfirmed } = await this.$swal.fire({
        title: "¿Esta seguro?",
        text: "Esta acción eliminará el contacto",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Si, continuar",
        denyButtonText: "Cancelar",
      });

      if (isConfirmed) {
        try {
          this.cargando = true;
          const response = await Contactos.eliminarContacto(payload);

          this.cargando = false;
          if (response.data.status === false) {
            this.$toastr.e(response.data.message);
          } else {
            this.$toastr.s(response.data.statusMessage);
            this.getContactosData();
          }
        } catch (error) {
          this.cargando = false;
          if (error.response.status === 400) {
            this.$toastr.e(error.response.data.message);
          } else {
            this.$toastr.e("Error al eliminar el contacto");
          }
        }
      }
    },
    //CREAR contacto
    async crearContacto() {
      const payload = {
        cliente_id: this.cliente_id,
        cliente_email: this.email,
      };

      this.validate();

      //controlo que no se repita el email, verificando que si existe en el array de contactos
      const existe = this.contactos.find(
        (item) => item.cliente_email.toLowerCase() === this.email.toLowerCase()
      );

      if (this.res && !existe) {
        try {
          this.cargando = true;
          const response = await Contactos.crearContacto(payload);
          this.cargando = false;
          if (response.data.status === false) {
            this.$toastr.e(response.data.message);
          } else {
            this.$toastr.s(response.data.statusMessage);
            this.getContactosData();
          }
        } catch (error) {
          this.cargando = false;
          if (error.response.status === 400) {
            this.$toastr.e(error.response.data.message);
          } else {
            this.$toastr.e("Error al crear el contacto");
          }
        }
      } else {
        existe
          ? this.$toastr.e("El email ingresado ya existe")
          : this.$toastr.e(
              "Debe completar los campos requeridos correctamente"
            );
      }

      this.closeForm();
    },

    //editar o crear contacto
    guardarOptions() {
      this.editar ? this.editarContacto() : this.crearContacto();
    },
    // ---------------------dialog FORM (editar o crear)----------------------

    //abrir form
    showDialogForm(contacto) {
      this.dialogForm = true;
      this.editar = false;
      this.email = "";

      if (contacto) {
        this.editar = true;
        this.email = contacto.cliente_email;
        this.email_anterior = contacto.cliente_email;
        this.cliente_id = contacto.cliente_id;
      }
    },
    //cerrar form
    closeForm() {
      this.dialogForm = false;
      this.editar = false;
      this.$refs.form.resetValidation();
    },
    // ---------------------------dialog LISTA contactos------------------------
    //cerrar dialog
    closeDialog() {
      this.dialog = false;
      this.contactos = [];
    },

    //VALIDAR FORMULARIO
    validate() {
      this.$refs.form.validate();
      this.res = this.$refs.form.validate();
    },
  },
};
</script>

<style scoped>
.card-title {
  font-family: "Montserrat" !important;
  font-weight: bold;
}
</style>
