var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"800px"},on:{"click:outside":function($event){return _vm.closeDialog()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-app',[_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',{staticStyle:{"font-family":"'Montserrat' !important","font-weight":"bold"}},[_vm._v(" Contactos del cliente "+_vm._s(this.cliente)+" "),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.closeDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("close")])],1)]}}])})],1),_c('section',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":"","color":"grey darken-1"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":""},on:{"click":function($event){return _vm.showDialogForm(null)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email-plus-outline")])],1)]}}])},[_c('span',[_vm._v("Agregar Contacto")])])],1),_c('v-data-table',{staticClass:"elevation-0 trow",attrs:{"height":"250px","search":_vm.search,"headers":_vm.headers,"items":_vm.contactos,"loading":_vm.cargando,"disable-pagination":"","hide-default-footer":"","loading-text":"Cargando... Por favor espere","no-data-text":"No se encontraron registros","no-results-text":"No se encontraron registros que coincidan"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"grey darken-1"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","dark":"","x-small":"","color":"orange"},on:{"click":function($event){return _vm.showDialogForm(item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" create ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar Contacto")])]),_c('v-tooltip',{attrs:{"top":"","color":"grey darken-1"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","x-small":"","color":"red"},on:{"click":function($event){return _vm.eliminarContacto(item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar Contacto")])])]}}],null,true)})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.dialogForm),callback:function ($$v) {_vm.dialogForm=$$v},expression:"dialogForm"}},[_c('v-card',[_c('v-card-title',{staticClass:"font-weight-bold",staticStyle:{"font-family":"'Montserrat'"}},[_vm._v(" "+_vm._s(_vm.editar ? "Modificar Contacto" : "Agregar Contacto")+" "),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.closeForm()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("close")])],1)]}}])})],1),_c('v-card-text',[_c('div',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"rules":_vm.rules_email,"label":"Email","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":_vm.closeForm}},[_vm._v(" Cerrar ")]),_c('v-btn',{staticClass:"font-weight-bold",staticStyle:{"font-family":"'Montserrat'"},attrs:{"disabled":_vm.cargando || !_vm.valid,"color":"green","text":""},on:{"click":_vm.guardarOptions}},[_vm._v(" Guardar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }